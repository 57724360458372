var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',{attrs:{"wrap":"","justify-start":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","pb-12":""}},[_c('v-img',{attrs:{"src":require("@/assets/conventions-grey.svg"),"cover":"","height":"60vh"}},[_c('v-layout',{attrs:{"wrap":"","justify-start":"","fill-height":""}},[_c('v-flex',{attrs:{"xs12":"","text-center":"","align-self-center":""}},[_c('div',{staticClass:"page-heading"},[_vm._v(" In questa sezione viene raccolto tutto il portafoglio di prodotti che CB Digital mette a disposizione dei propri clienti. I prodotti offerti sono l’esito di una lunga analisi dove vengono scelti dopo un’attenta analisi del mercato e una costante verifica dei premi più competitivi del mercato "),_c('br'),_c('br'),(_vm.isStaff || _vm.isAgent)?_c('v-btn',{attrs:{"color":"primary","dark":"","ripple":false,"depressed":"","href":"https://drive.google.com/file/d/1eBbejbqU4yZy4L_XuSc5enD5ITD_PeDX/view","target":"_blank"}},[_c('span',{staticClass:"normal-text",staticStyle:{"color":"white","font-weight":"bold","text-transform":"none"}},[_vm._v(" Analisi delle polizze ")])]):_vm._e()],1)])],1)],1)],1)],1),_c('v-layout',{staticClass:"product-container",attrs:{"wrap":"","justify-center":""}},[(_vm.isStaff || _vm.isAgent)?_c('v-flex',{attrs:{"xs12":"","sm9":"","md10":"","lg9":""}},[_c('AppCard',[_c('div',{staticStyle:{"padding":"16px","display":"flex","flex-wrap":"wrap","justify-content":"center","gap":"16px"}},_vm._l((_vm.companies),function(item){return _c('a',{key:item.id,staticStyle:{"width":"58px","height":"58px","min-width":"58px","min-height":"58px","max-width":"58px","max-height":"58px","border-radius":"50%","display":"flex","justify-content":"center","align-items":"center","background-size":"contain","background-position":"center","border":"1px solid rgb(81, 183, 157)"},style:({
                backgroundImage: `url(${item.logo})`,
                backgroundColor: item.color ?? 'rgb(81, 183, 157)',
              }),attrs:{"href":item.url}},[(item.logo === null)?_c('span',{staticStyle:{"font-size":"25px","color":"white","font-weight":"bold"}},[_vm._v(" "+_vm._s(item.name[0])+" ")]):_vm._e()])}),0)])],1):_vm._e(),_c('v-flex',{attrs:{"xs12":"","sm9":"","md10":"","lg9":""}},[_c('v-layout',[_c('div',{staticClass:"aaa"},_vm._l((_vm.categories),function(item,j){return _c('div',{key:j,staticStyle:{"break-inside":"avoid","padding":"20px 0"}},[_c('AppCard',{staticClass:"pa-6"},[_c('v-layout',{attrs:{"wrap":"","justify-start":""}},[_c('v-flex',{staticClass:"card-title",attrs:{"xs12":""}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-flex',{staticClass:"normal-text long-text",attrs:{"xs12":"","py-2":""}},[_c('span',{domProps:{"innerHTML":_vm._s(item.description)}})]),_vm._l((item.products),function(product,j){return _c('v-row',{key:j,staticClass:"normal-text flex xs12"},[_c('v-col',{attrs:{"cols":"10"}},[_c('span',{staticClass:"pointer",on:{"click":function($event){return _vm.openInfoDialog(product)}}},[_vm._v(" "+_vm._s(product.title)+" ")])]),(_vm.authState)?_c('v-col',{attrs:{"cols":"2"}},[(product.companiesList.length > 0)?_c('v-icon',{staticClass:"mdi mdi-open-in-new",on:{"click":function($event){return _vm.openDataDialog(product)}}}):_vm._e()],1):_vm._e()],1)})],2)],1)],1)}),0)])],1)],1)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('AppDialog',{attrs:{"value":_vm.showInfoDialog},on:{"close":_vm.closeInfoDialog}},[_c('AppCard',{staticClass:"pa-6"},[_c('v-layout',{attrs:{"wrap":"","justify-start":""}},[_c('v-flex',{attrs:{"xs12":"","text-right":""}},[_c('v-icon',{on:{"click":_vm.closeInfoDialog}},[_vm._v("mdi-close")])],1),_c('v-flex',{staticClass:"card-title primary-text",attrs:{"xs12":""}},[_vm._v(" "+_vm._s(_vm.selectedItem.title)+" ")]),_c('v-flex',{staticClass:"normal-text",attrs:{"xs12":"","py-2":""}},[(_vm.selectedItem.description)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.selectedItem.description)}}):_vm._e()]),(
              _vm.selectedItem.descriptions &&
              _vm.selectedItem.descriptions.length > 0
            )?_c('ul',_vm._l((_vm.selectedItem.descriptions),function(d){return _c('li',{key:d,staticClass:"normal-text"},[_vm._v(" "+_vm._s(d)+" ")])}),0):_vm._e()],1)],1)],1)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('AppDialog',{attrs:{"value":_vm.showDataDialog},on:{"close":_vm.closeDataDialog}},[_c('AppCard',{staticClass:"pa-6"},[_c('v-layout',{attrs:{"wrap":"","justify-start":""}},[_c('v-flex',{attrs:{"xs12":"","text-right":""}},[_c('v-icon',{on:{"click":_vm.closeDataDialog}},[_vm._v("mdi-close")])],1),_c('v-flex',{staticClass:"card-title primary-text",attrs:{"xs12":""}},[_vm._v(" "+_vm._s(_vm.selectedItem.title)+" ")]),_c('v-flex',{staticClass:"normal-text scroll-container",attrs:{"xs12":"","py-2":""}},_vm._l((_vm.selectedItem.companiesList),function(item,i){return _c('v-layout',{key:i,attrs:{"wrap":"","justify-start":"","py-2":""}},[_c('v-row',{staticClass:"normal-text flex xs12 pb-4"},[_c('v-col',{attrs:{"cols":"11"}},[_c('strong',[_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_vm._v(_vm._s(item.name))])])]),_c('v-col',{attrs:{"cols":"1"}},[_c('a',{staticClass:"mdi mdi-open-in-new",on:{"click":function($event){return _vm.goExternal(item.url)}}})])],1),_c('v-flex',{staticClass:"normal-text bold",attrs:{"xs12":""}},[_c('small',[_vm._v("Nome utente")])]),_c('v-flex',{staticClass:"normal-text",attrs:{"xs12":""}},[_vm._v(" "+_vm._s(item.username)+" ")]),_c('v-flex',{staticClass:"normal-text bold",attrs:{"xs12":""}},[_c('small',[_vm._v("Password")])]),_c('v-flex',{staticClass:"normal-text",attrs:{"xs12":""}},[_vm._v(" "+_vm._s(item.password)+" ")]),(item.description)?_c('v-flex',{staticClass:"font-italic",attrs:{"xs12":""}},[_c('small',[_vm._v("Nota: "+_vm._s(item.description))])]):_vm._e(),_c('v-flex',{attrs:{"xs12":"","pt-2":""}},[(i !== _vm.selectedItem.companiesList.length - 1)?_c('hr'):_vm._e()])],1)}),1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }