// singleton static only class
// This class is responsible for handling all the external gateway calls

export default class ExternalGateway {
  static navigate(url) {
    const link = document.createElement("a");
    link.href = url;
    link.style.display = "none";
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  //   static async ipaRedirect(url) {
  //     const authenticateUrl =
  //       "https://ipagencytest.siaspa.com/mit/api/authenticate-trusted";
  //     const body = {
  //       username: "cbdigital",
  //       password: "Cbdigital123*",
  //       trustedUsername: "cbdigital.trusted",
  //       trustedPassword: "c0b0t0l1%3cnPwd*",
  //     };
  //     await fetch(authenticateUrl, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(body),
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         if (data.url) {
  //           ExternalGateway.navigate(data.url);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error:", error);
  //       });
  //   }

  //   static async furnessRedirect(url) {
  //     const authenticateUrl =
  //       "https://fulws.uat.furness.eu/api/oraclient/GenerateWLAccessPost";
  //     const body = {
  //       CommercialProductPK: 103,
  //       UserCode: "21",
  //       ProducerMail: "cbdigital",
  //       ProducerFullName: "Consul Broker Digital S.r.l.",
  //     };
  //     await fetch(authenticateUrl, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(body),
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         if (data.AccessURL) {
  //           ExternalGateway.navigate(data.AccessURL);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error:", error);
  //       });
  //   }

  static async goExternal(store, url, productId) {
    // get full domain from url without protocol and path
    const parsed = new URL(url);
    switch (parsed.hostname) {
      case "ipagency.siaspa.com":
      case "www.ipagency.siaspa.com":
      case "fulws.uat.furness.eu":
      case "fulws.furness.eu":
        const response = await store.dispatch("retrieveExternalUrl", {
          productId,
          url,
        });
        return response.url;
      default:
        return url;
    }
    // ExternalGateway.goTo(url);
  }
}
