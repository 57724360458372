<template>
  <v-layout wrap justify-start>
    <v-flex xs12>
      <v-layout wrap justify-center>
        <v-flex xs12 pb-12>
          <v-img src="@/assets/conventions-grey.svg" cover height="60vh">
            <v-layout wrap justify-start fill-height>
              <v-flex xs12 text-center align-self-center>
                <div class="page-heading">
                  In questa sezione viene raccolto tutto il portafoglio di
                  prodotti che CB Digital mette a disposizione dei propri
                  clienti. I prodotti offerti sono l’esito di una lunga analisi
                  dove vengono scelti dopo un’attenta analisi del mercato e una
                  costante verifica dei premi più competitivi del mercato
                  <br />
                  <br />
                  <v-btn
                    color="primary"
                    dark
                    v-if="isStaff || isAgent"
                    :ripple="false"
                    depressed
                    href="https://drive.google.com/file/d/1eBbejbqU4yZy4L_XuSc5enD5ITD_PeDX/view"
                    target="_blank">
                    <span
                      class="normal-text"
                      style="
                        color: white;
                        font-weight: bold;
                        text-transform: none;
                      ">
                      Analisi delle polizze
                    </span>
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-img>
        </v-flex>
      </v-layout>
      <v-layout wrap justify-center class="product-container">
        <v-flex v-if="isStaff || isAgent" xs12 sm9 md10 lg9>
          <AppCard>
            <div
              style="
                padding: 16px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                gap: 16px;
              ">
              <a
                v-for="item in companies"
                :key="item.id"
                :style="{
                  backgroundImage: `url(${item.logo})`,
                  backgroundColor: item.color ?? 'rgb(81, 183, 157)',
                }"
                :href="item.url"
                style="
                  width: 58px;
                  height: 58px;
                  min-width: 58px;
                  min-height: 58px;
                  max-width: 58px;
                  max-height: 58px;
                  border-radius: 50%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-size: contain;
                  background-position: center;
                  border: 1px solid rgb(81, 183, 157);
                ">
                <span
                  v-if="item.logo === null"
                  style="font-size: 25px; color: white; font-weight: bold">
                  {{ item.name[0] }}
                </span>
              </a>
            </div>
          </AppCard>
        </v-flex>
        <v-flex xs12 sm9 md10 lg9>
          <v-layout>
            <div class="aaa">
              <div
                v-for="(item, j) in categories"
                :key="j"
                style="break-inside: avoid; padding: 20px 0">
                <AppCard class="pa-6">
                  <v-layout wrap justify-start>
                    <v-flex xs12 class="card-title">
                      {{ item.name }}
                    </v-flex>
                    <v-flex xs12 py-2 class="normal-text long-text">
                      <span v-html="item.description"></span>
                    </v-flex>
                    <v-row
                      v-for="(product, j) in item.products"
                      class="normal-text flex xs12"
                      :key="j">
                      <v-col cols="10">
                        <span @click="openInfoDialog(product)" class="pointer">
                          {{ product.title }}
                        </span>
                      </v-col>
                      <v-col cols="2" v-if="authState">
                        <v-icon
                          v-if="product.companiesList.length > 0"
                          class="mdi mdi-open-in-new"
                          @click="openDataDialog(product)"></v-icon>
                      </v-col>
                    </v-row>
                  </v-layout>
                </AppCard>
              </div>
            </div>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs12>
      <AppDialog :value="showInfoDialog" @close="closeInfoDialog">
        <AppCard class="pa-6">
          <v-layout wrap justify-start>
            <v-flex xs12 text-right>
              <v-icon @click="closeInfoDialog">mdi-close</v-icon>
            </v-flex>
            <v-flex xs12 class="card-title primary-text">
              {{ selectedItem.title }}
            </v-flex>
            <v-flex xs12 py-2 class="normal-text">
              <span
                v-html="selectedItem.description"
                v-if="selectedItem.description"></span>
            </v-flex>
            <ul
              v-if="
                selectedItem.descriptions &&
                selectedItem.descriptions.length > 0
              ">
              <li
                class="normal-text"
                v-for="d in selectedItem.descriptions"
                :key="d">
                {{ d }}
              </li>
            </ul>
          </v-layout>
        </AppCard>
      </AppDialog>
    </v-flex>
    <v-flex xs12>
      <AppDialog :value="showDataDialog" @close="closeDataDialog">
        <AppCard class="pa-6">
          <v-layout wrap justify-start>
            <v-flex xs12 text-right>
              <v-icon @click="closeDataDialog">mdi-close</v-icon>
            </v-flex>
            <v-flex xs12 class="card-title primary-text">
              {{ selectedItem.title }}
            </v-flex>
            <v-flex xs12 py-2 class="normal-text scroll-container">
              <v-layout
                wrap
                justify-start
                py-2
                v-for="(item, i) in selectedItem.companiesList"
                :key="i">
                <v-row class="normal-text flex xs12 pb-4">
                  <v-col cols="11">
                    <strong>
                      <a :href="item.url" target="_blank">{{ item.name }}</a>
                    </strong>
                  </v-col>
                  <v-col cols="1">
                    <a
                      class="mdi mdi-open-in-new"
                      @click="goExternal(item.url)"></a>
                  </v-col>
                </v-row>
                <v-flex xs12 class="normal-text bold">
                  <small>Nome utente</small>
                </v-flex>
                <v-flex xs12 class="normal-text">
                  {{ item.username }}
                </v-flex>
                <v-flex xs12 class="normal-text bold">
                  <small>Password</small>
                </v-flex>
                <v-flex xs12 class="normal-text">
                  {{ item.password }}
                </v-flex>
                <v-flex xs12 class="font-italic" v-if="item.description">
                  <small>Nota: {{ item.description }}</small>
                </v-flex>
                <v-flex xs12 pt-2>
                  <hr v-if="i !== selectedItem.companiesList.length - 1" />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </AppCard>
      </AppDialog>
    </v-flex>
  </v-layout>
</template>

<script>
import AppCard from "@/components/shared/AppCard";
import AppDialog from "@/components/shared/AppDialog";
import { mapGetters } from "vuex";
import ExternalGateway from "../externalGateway";
import store from "../store";

export default {
  name: "Products",
  components: {
    AppCard,
    AppDialog,
  },
  data() {
    return {
      loading: false,
      showInfoDialog: false,
      showDataDialog: false,
      selectedItem: {},
    };
  },
  computed: {
    ...mapGetters({
      authState: "getAuthState",
      isStaff: "getIsStaff",
      isAgent: "getIsAgent",
      categories: "getCategories",
      companies: "getCompanies",
    }),
  },
  mounted() {},
  methods: {
    openInfoDialog(item) {
      this.selectedItem = item;
      this.showInfoDialog = true;
    },
    closeInfoDialog() {
      this.selectedItem = {};
      this.showInfoDialog = false;
    },
    openDataDialog(item) {
      this.selectedItem = item;
      this.showDataDialog = true;
    },
    closeDataDialog() {
      this.selectedItem = {};
      this.showDataDialog = false;
    },
    goExternal(url) {
      ExternalGateway.goExternal(this.$store, url);
    },
  },
};
</script>

<style scoped>
.long-text {
  line-height: 1.4;
}

.pointer {
  text-decoration: underline;
}

.aaa {
  row-gap: 10px;
  column-count: 2;
  column-gap: 10px;
}
@media (max-width: 600px) {
  .aaa {
    column-count: 1;
  }
}
</style>
